import * as React from "react"
import { Helmet } from "react-helmet"

// markup
const NotFoundPage = () => {
  return (
    <main>
      <Helmet>
          <title>Not Found Bro</title>
      </Helmet>
      Not Found Bro Well Played Bro
    </main>
  )
}

export default NotFoundPage
